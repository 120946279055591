<template>
  <div class="main">
    <div class="solution">
      <div class="pageBanner">
        <div class="banner">
          <div class="describe">
            <div class="tit">解决方案<span>/SOLUTION</span></div>
            <div class="txt">
              为客户提供专业的数据处理、技术开发、数据解决方案，是一家集研发、生产、销售于一体的<br>大数据企业。
            </div>
          </div>
        </div>
      </div>
      <div class="serveCont">
        <div class="tabs">
          <ul>
            <li
              v-for="(item, k) in tabsList"
              :key="k"
              :class="activeClass == item.id ? 'active' : ''"
              @click="tabsCli(item)"
            >
              <router-link :to="item.url">{{ item.tit }}</router-link>
            </li>
            <!-- <li><a href="">数据应用研发服务中心</a></li>
                        <li><a href="">业务场景综合服务中心</a></li>
                        <li><a href="">智慧云服务中心</a></li> -->
          </ul>
        </div>
        <div class="tabsDtail">
          <div class="tit">
            <div style="font-size:18px;">数据应用研发服务中心</div>
          </div>
          <div class="dataCont">
            <div class="basiTit">
              为各行业系统单位提供数据中心、数据中台的解决方案与实施，完成数据分析、智能应用，打通数据之间的关联关系，辅助政府决策，更有效的协助项目落地。
            </div>
            <div class="basicImg">
              <img src="../assets/data1.png" alt="" />
            </div>

            <div class="system">
              <span class="line"></span>
              <div class="yy">应用</div>
              <div class="appTit textIndent">
                随着信息技术的迅速发展，信息资源数字化、网络化的进程进一步加快，档案管理的信息化建设、专题数据平台建设已经成一个备受关注的热点。企业，学校，政府单位等都需要对档案、专题数据进行信息化管理，来适应当今社会的信息化、网络化发展趋势。
              </div>
            </div>

            <div class="system">
              <span class="line"></span>
              <div class="yy">档案管理系统</div>
              <div class="dataimg">
                <img src="../assets/data2.png" alt="" />
              </div>
              <!-- <div class="dataimg2">
                <img src="../assets/data3.png" alt="" />
              </div> -->
            </div>

            <div class="dataTxt">
              <span class="line"></span>
              <div class="yy">专题数据平台</div>
              <div class="datapage">
                <div class="datapagebox">
                  <div class="imgs">
                    <img src="../assets/data4.png" alt="" />
                  </div>
                  <div class="datatit">长江经济带大数据平台</div>
                  <div class="datacont">
                    长江经济带大数据平台提供多种资源，帮助学科研究者从中获利，促进并完善社会科学发展；各级政府通过平台可直接了解到治下区域发展状况，有利于政府进行经济决策；能通过平台对长江经济带的基本面做到运筹帷幄，全局把握，从宏观层面能及时纠正发展过程中呈现的问题，提高长江经济带发展效率，提升长江经济带建设质量。
                  </div>
                </div>
                <div class="datapagebox">
                  <div class="imgs">
                    <img src="../assets/data5.png" alt="" />
                  </div>
                  <div class="datatit">中国革命老区大数据平台</div>
                  <div class="datacont">
                    中国革命老区大数据平台为全国革命老区各城市经济质量发展、绿色发展、现代化建设和现代化经济等发展现状和过程提供科学诊断，为革命老区发展提供决策方向，对革命老区实施精准扶贫、精准脱贫具有重要的意义。平台数据涉及五个跨省重点革命老区，八个连片革命老区和中央苏区，覆盖全国67个市级革命老区，1500个县级革命老区，数据类型包含宏观数据，微观数据，空间数据以及调查数据，为国家战略探究提供强大的数据支撑。
                  </div>
                </div>
                <div class="datapagebox">
                  <div class="imgs">
                    <img src="../assets/img11.png" alt="" />
                  </div>
                  <div class="datatit">财政部—中国财政基础数据库系统</div>
                  <div class="datacont">
                    中国财政基础数据库系统是服务于经济相关的高校、科研院所以及相关人士的专业的数据库系统。数据库建设共有五大类，十六个二级分类的数据信息，包括全国/地方财政决算、债务、彩票、财政月度、政府性基金和行政事业性收费等数据库。通过数据采集获取、数字化加工、清洗转换、存储入库等环节实现数据处理整合，以及经过算法加持后的程序开发，最终建设成为一套包含数据检索展示、多维查询、分析报告、可视化展示等完备功能的数据库系统。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeClass: 2,
      appDataList: [
        {
          id: 0,
          imgs: require("../assets/app1.png"),
          tit: "档案数字化",
          cont: "以国家档案局发布的《纸质档案数字化技术规范》图像处理检着标准为基础,可赧据客户具体需求针对性调整。",
        },
        {
          id: 1,
          imgs: require("../assets/app2.png"),
          tit: "表单数字化",
          cont: "利用新讯的智能表格识别技术，从各类机打或手写表单的单元格中采集数据，并填入到相应数据库字段中。广泛应用于各类申请表、登记表、申报表、调查问卷、票据、快运单等数据采集业务。",
        },
        {
          id: 2,
          imgs: require("../assets/app3.png"),
          tit: "报纸数字化",
          cont: "全流程系统70余道数据处理工序，对纸质或各类电子格式的报纸内容提供数据化服务，最终以检索系统的形式进行成品交付，其中图像为400dpi以上高清无倾斜无污渍内容，文字错误率控制在万分之一以下，内容标引项最多可达40余类，100%状态下双层PDF反显低于l毫米。",
        },
        {
          id: 3,
          imgs: require("../assets/app4.png"),
          tit: "图书数字化",
          cont: "提供标准差异化的图书扫描加工、精度细分化的图书文本制作、格式多样化的电子书转码加工、以及图书文字摘录等服务。内容可设计纸本简体新书、拥有电子数据的简体新书、港澳台及国外繁体新书、繁体旧书、民国图书、杂志、技术报告、学位论文、影印古籍、珍本古籍、特藏图书、年鉴、地方志等。",
        },
      ],
      tabsList: [
        {
          id: 1,
          url: "/solution/basic_data?tab=1",
          tit: "基础数据处理服务中心",
        },
        {
          id: 2,
          url: "/solution/data_apply?tab=2",
          tit: "数据应用研发服务中心",
        },
        {
          id: 3,
          url: "/solution/business_scenes?tab=3",
          tit: "业务场景综合应用中心",
        },
        {
          id: 4,
          url: "/solution/cloud?tab=4",
          tit: "智慧云服务中心\xa0 \xa0 \xa0 \xa0 \xa0\xa0",
        },
      ],
    };
  },
  methods: {
    tabsCli(id) {
      this.activeClass = id.id;
    },
  },
};
</script>
<style lang="scss">
@import "../scss/solution.scss";
</style>